import React, { useState } from "react";
import PortfolioData from "../../data/portfolio/PortfolioData.json";
import PortfolioItem from "./PortfolioItem";

const alldata = PortfolioData;
const PortfolioOne = ({ Column }) => {
  const [getAllItems] = useState(alldata);

  return (
    <>
      <div className="row row--15">
        {getAllItems.map((item) => (
          <div key={item.id} className={Column}>
            <PortfolioItem portfolio={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default PortfolioOne;
