import React from "react";
// import Typed from "react-typed";
import SEO from "../common/SEO";
import { HashLink as Link } from "react-router-hash-link";
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from "../common/header/HeaderOne";
import Copyright from "../common/footer/Copyright";
import AboutFour from "../elements/about/AboutFour";
import ServiceFour from "../elements/service/ServiceFour";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import SlpitOne from "../elements/split/SlpitOne";
import BrandThree from "../elements/brand/BrandThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import ContactForm from "../elements/contact/ContactForm";
import TimelineOne from "../elements/timeline/TimelineOne";
import FooterOne from "../common/footer/FooterOne";
import HeaderTopNews from "../common/header/HeaderTopNews";
import Mission from "../elements/about/Mission";
import PricingThree from '../elements/pricing/PricingThree';
import Typed from "../components/Typed";

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Блокчейн агентство PROEKTO: разработка в Web3, NFT коллекции, dApps, смарт контракты, криптовалюты, обучение крипто" />
      <main className="page-wrapper">
        <HeaderTopNews />
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">БЛОКЧЕЙН АГЕНТСТВО</span>
                  <h1 className="title theme-gradient display-two">
                    PROEKTO - ваш партнёр в <br />{" "}
                    <Typed
                      strings={[
                        "Web3 разработке.",
                        "создании smart контрактов.",
                        "разработке dApps.",
                        "запуске NFT коллекций.",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    Подготовьтесь к новой Интернет-эре Web 3.0 - используйте
                    децентрализованные технологии для улучшения показателей
                    вашего бизнеса.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to={"#contact"}
                      smooth
                    >
                      Хочу написать вам{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>

                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="#cases"
                      smooth
                    >
                      Хочу посмотреть проекты{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap" id="what_do_you_do">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Блокчейн агентство PROEKTO"
                  title="Разрабатываем решения c помощью blockchain технологий"
                  description="Более 15 лет опыта в веб-разработке, более 3 лет в blockchain. <br /> Более 80 успешных проектов реализовано для клиентов из Беларуси, США, Европы."
                />
              </div>
            </div>
            <ServiceFour
              serviceStyle="service__style--1 icon-circle-style with-working-process"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        <div className="rwt-elements-area rn-section-gap" id="portfolio">
          <div className="container-fluid plr--30">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Портфолио"
                  title="Реализованные проекты"
                  description=""
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay" />
          </div>
        </div>

        <Separator />

        {/* Start Timeline Area  */}
        <div className="rwt-timeline-area rn-section-gap" id="cases">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Кейсы из жизни"
                  title="Хотите посмотреть примеры реальных проектов в blockchain?"
                  description="Мы постарались максимально просто рассказать об этапах и составляющих реализации проектов в блокчейне, <br />а также о задачах, которые мы помогаем решить, и результатах, которых помогаем достигнуть."
                />
              </div>
            </div>
            <div className="row mt_dec--20">
              <div className="col-lg-12">
                <TimelineOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Timeline Area  */}

        <Separator />

        <AboutFour image="./images/about/about-4.png" />

        <Separator />

                {/* Start Mission Area   */}
                <Mission />                    
                {/* Start Mission Area  */}        
                
        <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-pricing-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--35">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Супер Предложение"
                                    title = "Лучшие цены на обучение"
                                    description = "Делимся практическими знаниями, полученными в реальных проектах, <br /> а также при прохождении обучений в университетах США и специализированных курсов от лидеров крипторынка."
                                />
                            </div>
                        </div>
                        <PricingThree />
                    </div>
                </div>
                {/* End Elements Area  */}

        <SlpitOne />

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Стек технологий, которые мы используем в блокчейн проектах
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Наша миссия - помощь в реализации и внедрении технологии
                  блокчейн в Беларуси. Мы выступаем за эффективное и прозрачное
                  использование технологии блокчейн, криптовалюты, и токенизации
                  активов.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        <div className="rwt-brand-area pb--60 pt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandThree brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        <Separator />

        <div className="contact-area rn-section-gapBottom" id="contact">
          <div className="container">
            <div className="row row--30 mt--40">
              <div className="col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30">
                <p>
                  Мы всегда рады обратной связи. Напишите нам свои вопросы,
                  предложения, бизнес-мечты, и мы предложим варианты их
                  реализации.
                </p>
                <ContactForm formStyle="contact-form-1" />
              </div>
              <div className="col-lg-6 order-1 oder-lg-2">
                <div className="thumbnail">
                  <img
                    className="radius w-100"
                    src="./images/about/contact-image.jpg"
                    alt="Corporate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;
