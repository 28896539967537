import { useState } from "react";
import { FiX, FiArrowRight } from "react-icons/fi";

const HeaderTopNews = () => {
  const [deactive, setClass] = useState("");
  return (
    <div
      className={`header-top-news bg_image ${deactive}`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/blockchain-channel.jpg)`,
      }}
    >
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <div className="content">
                  <span className="rn-badge">Обучайся с нами</span>
                  <span className="news-text">
                    Узнай о <strong>блокчейн</strong> и научись зарабатывать на <strong>криптовалютах</strong>{" "}
                  </span>
                </div>
                <div className="right-button">
                  <a
                    rel="noreferrer"
                    className="btn-read-more"
                    target="_blank"
                    href="https://forms.gle/bZBPcm7dgcG1Kk1J8"
                  >
                    <span>
                      Да, хочу на обучение<FiArrowRight />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="icon-close">
        <button className="close-button" onClick={() => setClass("deactive")}>
          <FiX />
        </button>
      </div>
    </div>
  );
};

export default HeaderTopNews;
