import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { HashLink as Link } from "react-router-hash-link";

const SlpitOne = () => {
  return (
    <div className="rn-splite-style" id="business">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src="./images/split/split-01.jpg" alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">
                  В каких бизнесах можно применить блокчейн технологию?
                </h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Блокчейн технологии открывают ранее неисследованные
                  возможности для бизнеса в рамках одной из самых быстрорастущих
                  отраслей в мире. Эта технология может радикально изменить
                  финансовую отрасль, недвижимость, логистику, энергетический
                  сектор, и все виды обмена данными. Технология blockchain —
                  сквозная, и это означает, что она может применяться в любых
                  отраслях. Но есть несколько ключевых типов бизнес-применений в
                  существующих компаниях:
                </p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <ul className="split-list">
                  <li>- новые способы хранения и передачи данных;</li>
                  <li>- создание прозрачных цепочек поставок;</li>
                  <li>
                    - автоматизация сложных отношений между стейкхолдерами;
                  </li>
                  <li>
                    - токенизация бизнеса, создание сообществ на основе владения
                    токенами.
                  </li>
                </ul>
                <p>
                  Эксперименты с Web 3.0 появляются сейчас во всех отраслях - от
                  искусства и электронной коммерции до банковской сферы и
                  здравоохранения. Если вы хотите узнать, как блокчейн может
                  помочь конкретно вашему бизнесу, напишите нам, чтобы обсудить
                  идеи и варианты.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="view-more-button mt--35">
                  <Link className="btn-default" to={"#contact"} smooth>
                    Пожалуй, напишу
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
