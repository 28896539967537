import React from "react";
import _Typed from "typed.js";

const Typed = (props) => {
  const el = React.useRef(null);
  const typed = React.useRef(null);

  React.useEffect(() => {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      typed.current = new _Typed(el.current, props);

      return () => {
        typed.current.destroy();
      };
    }
  }, [props]);

  if (
    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return <span ref={el} />;
  } else {
    const { strings } = props;
    return strings[0];
  }
};

export default Typed;
