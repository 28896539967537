import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    О технологии блокчейн
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Виды блокчейнов. Консенсус-механизмы. Форки. Смарт контракты. Децентрализованные приложения. Преимущества и минусы блокчейн технологии. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Криптовалюты
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Фиатные деньги. Биткоин, альткоин, стейблкоин. Токены и монеты. NFT. DAO. Криптотранзакции. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Криптокошельки
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Кастодиальные и некастодиальные кошельки. MetaMask. TrustWallet. Telegram Wallet. Покупка и продажа криптовалюты. P2P. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Виды заработка в криптовалюте
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Биржи - CEX, DEX. Стейкинг. Лендинг. Фарминг. Майнинг. ICOs, IDOs.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Анализ криптопроектов для инвестирования
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>Фундаментальный анализ токенов и проектов для инвестирования.</Card.Body>
                </Accordion.Collapse>
            </Card>            

        </Accordion>
    )
}
export default AccordionOne

