import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    date: "Кейс 1",
    title: "Запуск NFT коллекции Lord Society",
    description:
      "Подготовка метаданных для графических файлов, создание сайта с регистрацией на закрытую предпродажу, коннект кошелька Metamask и других кошельков на сайте, создание базы данных и API для presale mint, разработка и деплой смарт контракта, создание dApp для минта NFT коллекции Lord Society, последующая разработка смарт контракта и dApp для минта бесплатной коллекции Lord Angels, предназначенной только для владельцев NFT из коллекции Lord Society.",
    image: "timeline-01",
    workingStep: [
      {
        stepTitle: "Создание смарт контрактов",
        stepDescription:
          "В данном проекте были разработаны два взаимосвязанных смарт контракта для двух NFT коллекций (одной платной и второй бесплатной).",
      },
      {
        stepTitle: "Разработка dApp для минта NFT",
        stepDescription:
          "Было создано децентрализованное приложение для минта (продажи) NFT сначала закрытому кругу зарегистрированных кошельков, а потом для публичной продажи.",
      },
    ],
  },
  {
    id: "2",
    date: "Кейс 2",
    title: "Интеграция NFT в игровой проект Faded Arcade",
    description:
      "Разработка и деплой смарт контракта, запуск NFT коллекции, создание dApp для минта NFT Faded Arcade, коннект кошелька Metamask и других кошельков на сайте, создание базы данных для хранения пользовательских данных, интеграция NFT в игру, верификация владения NFT для доступа в игровую зону, создание Leaderboard игры на основе базы данных, создание смарт контрактов для калькуляции и выплаты роялти изначальным минтерам NFT, последующее создание отдельного сайта и dApp для взаимосвязанной бесплатной игры HamsterJamz.",
    image: "timeline-02",
    workingStep: [
      {
        stepTitle: "Коннект криптокошелька и верификация пользователей",
        stepDescription:
          "Была реализована Web3 авторизация на сайте, а также интегрирована проверка доступа к различным разделам сайта только для владельцев NFT.",
      },
      {
        stepTitle: "Система распределения роялти",
        stepDescription:
          "В данном проекте была задача делить и выплачивать роялти от последующих продаж NFT между изначальными покупателями, что было реализовано с помощью смарт контрактов.",
      },
    ],
  },
];

const TimelineOne = () => {
  return (
    <div className="rn-timeline-wrapper timeline-style-one position-relative">
      <div className="timeline-line"></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className="single-timeline mt--50" key={index}>
          <div className="timeline-dot">
            <div className="time-line-circle"></div>
          </div>
          <div className="single-content">
            <div className="inner">
              <div className="row row--30 align-items-center">
                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                  <div className="content">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <span className="date-of-timeline">{data.date}</span>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <h2 className="title">{data.title}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <p className="description">{data.description}</p>
                    </ScrollAnimation>

                    <div className="row row--30">
                      {data.workingStep.map((data, index) => (
                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                          <div className="working-list">
                            <ScrollAnimation
                              animateIn="fadeInUp"
                              animateOut="fadeInOut"
                              animateOnce={true}
                            >
                              <h5 className="working-title">
                                {data.stepTitle}
                              </h5>
                            </ScrollAnimation>

                            <ScrollAnimation
                              animateIn="fadeInUp"
                              animateOut="fadeInOut"
                              animateOnce={true}
                            >
                              <p>{data.stepDescription}</p>
                            </ScrollAnimation>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="read-morebtn">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <button className="btn-default btn-large round">
                          <span>Посмотреть кейс</span>
                        </button>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-lg-2 col-lg-6">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={`./images/timeline/${data.image}.jpg`}
                      alt="Corporate React Template"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimelineOne;
