import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages import Here
import BusinessConsulting2 from "./pages/BusinessConsulting2";

// Import Css Here
import "./assets/scss/style.scss";

const App = () => {
  return (
    <Router>
      <>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={BusinessConsulting2}
          />
        </Switch>
      </>
    </Router>
  );
};

export default App;
