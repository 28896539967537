import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap" id="studies">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Обучение <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Крипто Старт",
                                        "Крипто Инвестор",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>Технология блокчейн и криптовалюты проникают в наше Настоящее и становятся неотъемлемой частью нашего Будущего. Чем раньше вы разберётесь в криптовалютах, тем больше преимуществ вы будете иметь в вашей профессиональной и личной жизни. 
                                Специалисты PROEKTO подготовили для вас два обучающих курса, которые расскажут вам о технологии блокчейн и её применении в реальной жизни, научат вас безопасно приобретать и хранить криптовалюты, познакомят с самыми популярными криптокошельками и научат проводить криптотранзакции.</p>

                            <p>Вы также узнаете о рисках и возможностях, которые открывает крипторынок для инвесторов, познакомитесь с криптовалютными биржами и инструментами пассивного заработка, научитесь правильно анализировать криптовалютные проекты и токены для инвестирования. Вы готовы получить знания от профессионалов крипторынка?</p>

                            <div className="btn-default btn-large round">
                                <Link className="btn-default" target="_blank" to="https://forms.gle/bZBPcm7dgcG1Kk1J8">Да, хочу на обучение <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
