import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Спасибо! Мы скоро получим Ваше сообщение и свяжемся с Вами.
    </p>
  );
};

function ContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6jnghjd",
        "template_9cbrq9sf",
        e.target,
        "uWOvghejUIhODzbp0"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <input type="text" name="fullname" placeholder="Ваше имя" required />
      </div>

      <div className="form-group">
        <input type="email" name="email" placeholder="Ваш email" required />
      </div>

      <div className="form-group">
        <input
          type="text"
          name="phone"
          placeholder="Ваш номер телефона"
          required
        />
      </div>

      <div className="form-group">
        <textarea
          name="message"
          placeholder="Ваше сообщение"
          required
        ></textarea>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Отправить</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
