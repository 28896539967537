import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: ["https://www.youtube.com/watch?v=tS7i7-76sIA"],
  },
];

const AboutFour = ({ image }) => {
  return (
    <div className="about-area about-style-4 rn-section-gap" id="blockchain">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            {PopupData.map((item) => (
              <div className="video-btn" key={item.id}>
                <VideoTwo imagename={`${image}`} galleryItem={item} />
              </div>
            ))}
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="title">
                  Основные преимущества <br /> технологии{" "}
                  <strong>Blockchain</strong>
                </h3>
                <p>
                  Технология блокчейн даёт возможность всем участникам
                  обмениваться информацией и активами безопасно, с минимальными
                  затратами и в кратчайшие сроки.{" "}
                </p>
                <ul className="feature-list">
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Распределённость данных</h4>
                      <p className="text">
                        В блокчейн-системах все участники - децентрализованные и
                        равнозначные. Это значит, что даже если один из узлов
                        сети отключится, система продолжит работать.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Прозрачность данных</h4>
                      <p className="text">
                        Все участники видят любые изменения в сети, код
                        блокчейна открытый.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Неизменность данных</h4>
                      <p className="text">
                        Без согласия большинства участников сети данные менять
                        нельзя, транзакции необратимы.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Автономность</h4>
                      <p className="text">
                        Ряд процессов можно автоматизировать и сделать
                        самоисполняющимися, например, с помощью
                        смарт-контрактов.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Анонимность и нативные платежи</h4>
                      <p className="text">
                        Криптокошельки позволяют легко, анонимно и безопасно
                        интегрировать международные платежи и транзакции в
                        web3-приложения.
                      </p>
                    </div>
                  </li>
                </ul>
                <p>
                  Чтобы узнать больше о блокчейне и криптовалютах, научиться использовать криптокошельки и инвестировать в токены и крипто проекты, приходите к нам на обучение.
                  Жмите на кнопку и регистрируйтесь на обучение прямо сегодня!
                </p>
                <div className="about-btn mt--30">
                  <a
                    className="btn-default" target="_blank" 
                    href="https://forms.gle/bZBPcm7dgcG1Kk1J8"
                  >
                    Зарегистрироваться
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
