import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="/">PROEKTO</Link>
      </li>

      <li>
        <Link smooth to={"#what_do_you_do"}>Что делаем</Link>
      </li>

      <li>
        <Link smooth to={"#portfolio"}>Портфолио</Link>
      </li>

      <li>
        <Link smooth to={"#cases"}>Кейсы</Link>
      </li>

      <li>
        <Link smooth to={"#blockchain"}>Блокчейн</Link>
      </li>

      <li>
        <Link smooth to={"#studies"}>Обучение</Link>
      </li>

    </ul>
  );
};
export default Nav;
